import { useState } from 'react';
import styles from './index.module.scss';
import clsx from 'clsx';
import { ReactNode } from 'react';

interface ITab {
    id: string;
    tabTitle: string;
    tabContent: ReactNode;
}

interface ITabsState {
    tabs: ITab[];
    currentTabId: string;
}

interface IContestDetailTabs {
    tabsData: ITab[];
}

export const ContestDetailTabs: React.FC<IContestDetailTabs> = ({ tabsData }) => {
    const [{ currentTabId, tabs }, setTabsState] = useState<ITabsState>({
        tabs: tabsData,
        currentTabId: tabsData[0].id,
    });
    const changeTab = (id: string) => {
        setTabsState((prevState) => {
            return {
                ...prevState,
                currentTabId: id,
            };
        });
    };

    return (
        <div className={styles['user-page-tabs__container']}>
            <ul className={styles['user-page-tabs__list']}>
                {tabs.map((tab) => {
                    const isSectionActive = currentTabId === tab.id;
                    return (
                        <li
                            key={tab.id}
                            className={clsx(
                                styles['user-page-tabs__list-item-container'],
                                isSectionActive &&
                                    styles['user-page-tabs__list-item-container--active']
                            )}
                        >
                            <div
                                onClick={() => {
                                    changeTab(tab.id);
                                }}
                                className={clsx(
                                    styles['user-page-tabs__list-item'],
                                    isSectionActive && styles['user-page-tabs__list-item--active'],
                                    styles['user-page-tabs__list-item--desktop-visible']
                                )}
                            >
                                {tab.tabTitle}
                            </div>
                        </li>
                    );
                })}
            </ul>
            {tabs.map((tab) => {
                const isSectionActive = currentTabId === tab.id;

                return (
                    <section
                        key={tab.id}
                        className={clsx(
                            styles['user-page-tabs__content'],
                            isSectionActive && styles['user-page-tabs__content--active']
                        )}
                    >
                        {tab.tabContent}
                    </section>
                );
            })}
        </div>
    );
};
