import React from 'react';
import { IContest } from '../../model';
import AdminContestPreview from '../AdminContestPreview';
import { useCreateModalState } from '../../../../shared/hooks/modal';
import { ReactNode } from 'react';
import { AddContestCardBtn } from '../../../../features/contest/addContestCardBtn';

interface IAdminContestCard {
    contest: IContest;
    deleteAction: ReactNode;
    editAction: ReactNode;
    previewAction: ReactNode;
    addContestCard: ReactNode;
    editCards: ReactNode;
}

export const AdminContestCard: React.FC<IAdminContestCard> = ({
    contest,
    deleteAction,
    editAction,
    previewAction,
    addContestCard,
    editCards,
}) => {
    const { isModalOpen, toggleModalStatus } = useCreateModalState();

    return (
        <>
            <AdminContestPreview
                title={contest.name}
                endDate={contest.end_date}
                rubrics={contest.rubric}
                bgUrl={contest.image_url}
                text={contest.description}
                toggleModalStatus={toggleModalStatus}
                deleteAction={deleteAction}
                editAction={editAction}
                previewAction={previewAction}
                isActive={contest.is_active}
                addContestCard={addContestCard}
                editCards={editCards}
            />
        </>
    );
};
