import { BASE__API__URL } from '../../../shared/consts';
import { IContest } from '.';

export async function contestsRequest(): Promise<IContest[]> {
    try {
        const response = await fetch(`${BASE__API__URL}contests`);
        if (response.ok) {
            const data = await response.json();
            return data;
        } else {
            return [];
        }
    } catch (err) {
        return [];
    }
}

export async function getContestById(id: string): Promise<IContest | null> {
    try {
        const response = await fetch(`${BASE__API__URL}contests/${id}`);
        if (response.ok) {
            const data = await response.json();
            return data;
        } else {
            return null;
        }
    } catch (err) {
        return null;
    }
}

interface ICertificateTemplateResponse {
    id: number;
    name: string;
    certificate_template_url: string;
}

export async function getContestCertificateTemplate({
    media_id,
    contest_id,
}: {
    media_id?: number;
    contest_id: number;
}): Promise<ICertificateTemplateResponse | null> {
    try {
        const mediaId = media_id? `&media_id=${media_id}`: ''

        const response = await fetch(
            `${BASE__API__URL}contests/certificate_template?contest_id=${contest_id}${mediaId}`
        );
        if (response.ok) {
            const data = await response.json();
            return data;
        } else {
            return null;
        }
    } catch (err) {
        return null;
    }
}
