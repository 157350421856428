import React, { ReactNode } from 'react';
import styels from './index.module.scss';
import { BASE__MEDIA__URL } from '../../../../shared/consts';
import { PulseIcon } from '../../../../shared/assets/PulseIcon';

interface IAdminContestPreview {
    toggleModalStatus: () => void;
    bgUrl: string;
    rubrics: string;
    text: string;
    endDate: string;
    title: string;
    deleteAction: ReactNode;
    editAction: ReactNode;
    previewAction: ReactNode;
    addContestCard: ReactNode;
    isActive: boolean;
    editCards: ReactNode;
}

const AdminContestPreview: React.FC<IAdminContestPreview> = ({
    toggleModalStatus,
    bgUrl,
    rubrics,
    title,
    endDate,
    deleteAction,
    editAction,
    previewAction,
    isActive,
    addContestCard,
    editCards,
}) => {
    const contestEndDate = new Date(endDate).toLocaleString('ru', {
        month: 'long',
        day: 'numeric',
    });
    return (
        <div className={styels['admin-contest-preview__container']} onClick={toggleModalStatus}>
            <article
                className={styels['admin-contest-preview']}
                style={{ backgroundImage: `url(${BASE__MEDIA__URL + bgUrl})` }}
            >
                <div className={styels['admin-contest-preview__info']}>
                    <span className={styels['admin-contest-preview__rubrics']}>{rubrics}</span>
                    <h2 className={styels['admin-contest-preview__title']}>{title}</h2>
                </div>
                <div className={styels['admin-contest-preview__actions']}>
                    <span className={styels['admin-contest-preview__status']}>
                        <PulseIcon className={styels['admin-contest-preview__status-icon']} />{' '}
                        {isActive ? 'активен' : 'не активен'}
                    </span>
                    {deleteAction}
                    {editAction}
                    {previewAction}
                    {addContestCard}
                    {editCards}
                </div>
            </article>
        </div>
    );
};

export default AdminContestPreview;
