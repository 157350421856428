import React from 'react';
import { ISvgIcon } from '../../types/icons';

interface ISocialIcon extends ISvgIcon {
    variant?: 'white' | 'gray';
}

const Vk: React.FC<ISocialIcon> = ({ className = '', variant = 'white' }) => {
    const isWhite = variant === 'white';

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="33"
            height="32"
            viewBox="0 0 33 32"
            fill="none"
            className={className}
        >
            <rect x="0.359375" width="32" height="32" rx="16" fill={isWhite ? 'white' : '#525D6C'} />
            <path
                d="M17.1466 21.5999C11.1333 21.5999 7.70346 17.3957 7.56055 10.3999H10.5727C10.6716 15.5346 12.8922 17.7096 14.6511 18.1581V10.3999H17.4873V14.8283C19.2243 14.6377 21.0491 12.6197 21.6647 10.3999H24.501C24.269 11.5512 23.8066 12.6412 23.1426 13.6019C22.4787 14.5626 21.6275 15.3732 20.6424 15.9831C21.742 16.5403 22.7133 17.3291 23.4922 18.2973C24.271 19.2655 24.8396 20.3911 25.1605 21.5999H22.0385C21.7504 20.55 21.1649 19.6101 20.3553 18.8982C19.5457 18.1862 18.548 17.7337 17.4873 17.5975V21.5999H17.1466Z"
                fill={isWhite?"#33373E": 'white'}
            />
        </svg>
    );
};

export default Vk;
