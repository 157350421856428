import React from 'react';
import styles from './index.module.scss';
import Tg from '../../assets/social/Tg';
import Vk from '../../assets/social/Vk';
import Zen from '../../assets/social/Zen';
import clsx from 'clsx';

interface ISocialList {
    iconClassName?: string;
    variant?: 'white' | 'gray';
}

export const SocialList: React.FC<ISocialList> = ({ iconClassName, variant = 'white' }) => {
    return (
        <ul className={styles.social_list}>
            <li>
                <a href="https://vk.com/podvigrf" target="_blank">
                    <Vk className={clsx(styles.icon, iconClassName)} variant={variant}></Vk>
                </a>
            </li>
            <li>
                <a href="https://t.me/podvigrf" target="_blank">
                    <Tg className={clsx(styles.icon, iconClassName)} variant={variant}></Tg>
                </a>
            </li>
            <li>
                <a href=" https://dzen.ru/podvigrf" target="_blank">
                    <Zen className={clsx(styles.icon, iconClassName)} variant={variant}></Zen>
                </a>
            </li>
        </ul>
    );
};
