import Modal from '../../../../shared/ui/Modal';
import { ContestDetail } from '../ContestDetail';
import { useCreateModalState } from '../../../../shared/hooks/modal';
import { ContestPreview } from '../ContestPreview';
import { IContest } from '../../model';
import React from 'react';
import styles from './index.module.scss';

interface IContestCard {
    contest: IContest;
    createGalleryItemForm: React.ReactNode;
}

export const ContestCard: React.FC<IContestCard> = ({ contest, createGalleryItemForm }) => {
    const { isModalOpen, toggleModalStatus } = useCreateModalState();

    return (
        <>
            <ContestPreview
                title={contest.name}
                endDate={contest.end_date}
                rubrics={contest.rubric}
                bgUrl={contest.image_url}
                text={contest.description}
                toggleModalStatus={toggleModalStatus}
                startDate={contest.start_date}
                contest={contest}
            />
            {isModalOpen && (
                <Modal
                    isOpen={isModalOpen}
                    setStatus={toggleModalStatus}
                    modalClassName={styles.modal}
                >
                    <ContestDetail
                        contest={contest}
                        createGalleryItemForm={createGalleryItemForm}
                    />
                </Modal>
            )}
        </>
    );
};
