import certificate from '../assets/certificateMid.png';
import certificate2 from '../assets/certificate2.png';
import certificate3 from '../assets/certificate3.png';
import certificate4 from '../assets/certificate4.png';

export const BASE__API__URL = process.env.REACT_APP_API || 'http://localhost:3000/api/';
export const BASE__MEDIA__URL = process.env.REACT_APP_MEDIA_SRC || 'http://localhost:3000/api/';
export const BASE__APP__URL = process.env.REACT_APP_BASE_URL || 'http://localhost:3000/';
export const VK__CLIENT__ID = process.env.REACT_APP_VK_CLIENT_ID || '51780578';

export const APP__ROUTS = {
    main: '/',
    feats: '/подвиги',
    featsId: ':id',
    contests: '/конкурсы',
    puzzle: '/игра',
    puzzleGame: 'пазл',
    puzzleTest: 'тест',
    puzzleRefQuestion: 'вопрос',
    puzzleFinal: 'финал',
    puzzleFinalRefQuestion: 'финальный-вопрос',
    profileLayout: '/профиль',
    gallery: '/галерея',
    galleryMediaItemId: ':id',
    notExistRouteRedirect: '*',
    userLayout: '',
    userInfo: '',
    adminLayout: 'админ-панель',
    adminGallery: '',
    adminContests: 'конкурсы',
    adminFeats: 'подвиги',
    adminPuzzles: 'пазлы',
    registration: 'регистрация',
    banner: 'промо',
    contetSubmitForm: 'отправить-работу',
    galleryWorks: 'работы',
    galleryWorksType: 'mediaType',
    imageVoting: 'фото-голосование',
    login: 'войти',
};

export const STATUS__CODE = {
    notAuth: 401,
};

export const GALLERY__INITIAL__RECORDS__LIMIT = 7;
export const GALLERY__RECORDS__PER__ONE__PAGINATION = 8;

export const USER__PLACEMENTS__DATA = [
    {
        value: 1,
        option: 'Участник',
    },
    {
        value: 2,
        option: 'Финалист',
    },
    {
        value: 3,
        option: 'Призер',
    },
    {
        value: 4,
        option: 'Победитель',
    },
];

export const CERTIFICATES__VARIANTS = [
    {
        value: certificate,
        option: 'Первый  вариант',
    },
    {
        value: certificate2,
        option: 'Второй вариант',
    },
    {
        value: certificate3,
        option: 'Сертификат Фотоквест',
    },
    {
        value: certificate4,
        option: 'Писатель:ода подвигу 2025',
    },
];

export const WORKS__TYPES = {
    image: {
        option: 'изображения',
        value: 'image',
    },
    video: {
        option: 'видео',
        value: 'video',
    },
    audio: {
        option: 'аудио',
        value: 'audio',
    },
    text: {
        option: 'текст',
        value: 'text',
    },
};
