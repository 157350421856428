import styles from './index.module.scss';
import Input from '../../../../../shared/ui/Input';
import Button from '../../../../../shared/ui/Button';
import clsx from 'clsx';
import VkAuthLink from '../VkAuthLink';
import { useSignInFormState } from '../../model/signIn';
import { useState } from 'react';
import ViewIcon from '../../../../../entities/feat/assets/ViewIcon';

interface ISignInForm {
    navMenu?: JSX.Element;
    setModalStatus: () => void;
    actionBtn?: JSX.Element;
}

export const SignInForm: React.FC<ISignInForm> = ({ navMenu, setModalStatus, actionBtn }) => {
    const {
        handleSubmit,
        watchMail,
        watchPassword,
        mailRegister,
        passwordRegister,
        mailErrorText,
        passwordErrorText,
        isMailnotVerif,
        isSubmitting,
    } = useSignInFormState(setModalStatus);
    const [passwordInputType, setType] = useState<'password' | 'text'>('password');

    return (
        <>
            <form className={styles['signin__form']} onSubmit={handleSubmit}>
                <h2 className={styles['signin__form-title']}>Вход</h2>
                <Input
                    isEmpty={!watchMail}
                    type={'text'}
                    title="Почта"
                    register={mailRegister}
                    errorText={mailErrorText}
                    titleClassName={styles['signin__form-input-title']}
                />

                <Input
                    className={styles['signin__form-input-pass']}
                    isEmpty={!watchPassword}
                    type={passwordInputType}
                    title="Пароль"
                    register={passwordRegister}
                    errorText={passwordErrorText}
                    titleClassName={styles['signin__form-input-title']}
                    atr={{ autoComplete: 'on' }}
                    placeHolderContent={
                        watchPassword && (
                            <ViewIcon
                                className={styles['signin__icon']}
                                onClick={() => {
                                    setType((prevState) => {
                                        return prevState === 'password' ? 'text' : 'password';
                                    });
                                }}
                            />
                        )
                    }
                />

                <Button
                    text="Войти"
                    variant="orange-gradient"
                    type="submit"
                    className={clsx(styles['signin__form-btn'], styles['signin__form-btn'])}
                    isLoading={isSubmitting}
                />
                <VkAuthLink className={styles['signin__form-vk-btn']} />
                {navMenu}
                {isMailnotVerif && actionBtn}
            </form>
        </>
    );
};
