import { ReactNode } from 'react';
import styels from './index.module.scss';
import clsx from 'clsx';

export interface IInputProps {
    title?: string;
    register: any;
    errorText?: any;
    type: string;
    isEmpty: boolean;
    className?: string;
    inputClassName?: string;
    isDisabled?: boolean;
    titleClassName?: string;
    atr?: Record<string, string | number>;
    variant?: 'primary' | 'admin';
    titleIcon?: ReactNode;
    placeHolderContent?: ReactNode;
}

const Input: React.FC<IInputProps> = ({
    title,
    register,
    errorText,
    type,
    isEmpty,
    className = '',
    inputClassName,
    isDisabled = false,
    titleClassName,
    variant = 'primary',
    titleIcon,
    atr,
    placeHolderContent,
}) => {
    return (
        <div className={clsx(className, styels['input__container'])}>
            <label htmlFor={title} className={styels['input__label']}>
                <div className={clsx(styels.input__title_container, titleClassName)}>
                    <span className={clsx(styels['input__title'])}>{title}</span>
                    {titleIcon}
                </div>
                <div
                    className={clsx(
                        styels.input__main_content_container,
                        inputClassName,
                        errorText && styels['input--invalid'],
                        !errorText && !isEmpty && styels['input--valid'],
                        isDisabled && styels['input--disabled'],
                        { primary: styels['input--primary'], admin: styels['input--admin'] }[
                            variant
                        ]
                    )}
                >
                    <input
                        className={clsx(styels['input'])}
                        type={type}
                        id={title}
                        readOnly={isDisabled ? true : false}
                        {...register}
                        {...atr}
                    />
                    {placeHolderContent}
                </div>
                <span className={styels['input__error-text']} title={errorText}>
                    {errorText}
                </span>
            </label>
        </div>
    );
};

export default Input;
