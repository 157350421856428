import styles from './index.module.scss';
import { useNavigate } from 'react-router-dom';
import { APP__ROUTS } from '../../../../shared/consts';
const GameBanner = () => {
    const navigate = useNavigate();
    const redirectToContest = () => {
        navigate(APP__ROUTS.contests + '/' + APP__ROUTS.contetSubmitForm + '?contest=16');
    };

    return (
        <div className={styles['header__advertisement']} id="header__feat__advertisement">
            <div className={styles['header__advertisement-info']}>
                <div className={styles['header__advertisement-info-container']}>
                    <ul className={styles['header__advertisement-title-list']}>
                        <li className={styles['header__advertisement-title']}>
                            КОНКУРС «ХУДОЖНИК: ОДА ПОДВИГУ»
                        </li>
                        <li className={styles['header__advertisement-subtitle']}>
                            Создай иллюстрацию к рассказу о герое и выиграй 50 тысяч рублей!{' '}
                        </li>
                    </ul>
                    <button className={styles.btn} onClick={redirectToContest}>
                        Участвовать
                    </button>
                </div>
            </div>
        </div>
    );
};

export default GameBanner;
