import { ISvgIcon } from '../types/icons';

export const PulseIcon: React.FC<ISvgIcon> = ({ className = '' }) => {
    return (
        <div>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                viewBox="0 0 24 24"
                version="1.1"
                className={className}
            >
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="ic_fluent_activity_24_filled" fill="#212121" fillRule="nonzero">
                        <path
                            fill="currentColor"
                            d="M8.47096081,7.23691331 L11.5265159,20.2289405 C11.7600677,21.221988 13.1560977,21.268924 13.4558214,20.2938058 L16.4005221,10.7135452 L16.7855356,12.2410553 C16.897511,12.6853085 17.2970602,12.9966465 17.7552079,12.9966465 L21,12.9966465 C21.5522847,12.9966465 22,12.5489312 22,11.9966465 C22,11.4443617 21.5522847,10.9966465 21,10.9966465 L18.5344311,10.9966465 L17.4654602,6.75559114 C17.2181786,5.77452142 15.837182,5.73909386 15.5399228,6.70619415 L12.6284436,16.1783724 L9.4752188,2.77105953 C9.23522942,1.75063984 7.78657863,1.74010295 7.53177121,2.75692369 L5.46696027,10.9966465 L3,10.9966465 C2.44771525,10.9966465 2,11.4443617 2,11.9966465 C2,12.5489312 2.44771525,12.9966465 3,12.9966465 L6.2472882,12.9966465 C6.70594806,12.9966465 7.10580602,12.6846261 7.21729537,12.2397228 L8.47096081,7.23691331 Z"
                            id="🎨-Color"
                        ></path>
                    </g>
                </g>
            </svg>
        </div>
    );
};
