import FooterLogoContainer from '../FooterLogoContainer';
import FooterLayout from '../FooterLayout';
import FooterNav from '../FooterNav';
import FooterPolicy from '../FooterPolicy';
import FooterCopyright from '../FooterCopyright';
import styles from './index.module.scss';

import { SocialList } from '../../../../shared/ui/SocialList';

export const Footer = ({ className = '' }) => {
    return (
        <FooterLayout className={className}>
            <div className={styles.nav}>
                <FooterLogoContainer />
                <SocialList />
                <FooterNav />
            </div>
            <FooterPolicy />
            <FooterCopyright />
        </FooterLayout>
    );
};
