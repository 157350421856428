import React from 'react';
import { ISvgIcon } from '../../types/icons';

interface ISocialIcon extends ISvgIcon {
    variant?: 'white' | 'gray';
}

const Tg: React.FC<ISocialIcon> = ({ className = '', variant = 'white' }) => {
    const isWhite = variant === 'white';
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="33"
            height="32"
            viewBox="0 0 33 32"
            fill="none"
            className={className}
        >
            <rect
                x="0.359375"
                width="32"
                height="32"
                rx="16"
                fill={isWhite ? 'white' : '#525D6C'}
            />
            <path
                d="M22.7586 10.0819L20.3543 22.6339C20.3543 22.6339 20.0178 23.5042 19.0937 23.0868L13.5463 18.6822L13.5205 18.6692C14.2699 17.9724 20.0805 12.5622 20.3345 12.317C20.7276 11.9372 20.4835 11.7111 20.0271 11.998L11.444 17.6425L8.1327 16.4887C8.1327 16.4887 7.6116 16.2968 7.56147 15.8794C7.51068 15.4613 8.14985 15.2352 8.14985 15.2352L21.6491 9.75126C21.6491 9.75126 22.7586 9.24644 22.7586 10.0819Z"
                fill={isWhite ? '#33373E' : 'white'}
            />
        </svg>
    );
};

export default Tg;
