import styels from './index.module.scss';
import { APP__ROUTS } from '../../../../shared/consts';
import { NavLink } from 'react-router-dom';
import Button from '../../../../shared/ui/Button';
import { HEADER__NAVLINKS__DATA } from '../../consts';
import clsx from 'clsx';
import { useAppSelector } from '../../../../shared/store';
import { getAuthStatus, getUserLoadingDataStatus } from '../../../../entities/session';
import CircledProfileIcon from '../../../../shared/assets/CircledProfileIcon';
import { changeModalVisibleStatus } from '../../../../entities/authModal';
import { useAppDispatch } from '../../../../shared/store';
import Logo from '../../../../shared/ui/Logo';
import { ButtonSkeleton } from '../../../../shared/ui/ButtonSkeleton';
import internetWithoutDangerLogo from '../../../../shared/assets/projectsLogo/internetWithoutDangerLogoGray.svg';
import ncptiLogo from '../../../../shared/assets/projectsLogo/ncptiLogoGray.svg';
import { SocialList } from '../../../../shared/ui/SocialList';

const HeaderNavPanel = () => {
    const isAuth = useAppSelector(getAuthStatus);
    const userLoadingDataStatus = useAppSelector(getUserLoadingDataStatus);
    const dispatch = useAppDispatch();
    const toggleModalstatus = () => {
        dispatch(changeModalVisibleStatus());
    };
    return (
        <>
            <div className={styels['header__nav-panel']}>
                <div className={styels['header__social-media-container']}>
                    <Logo path={APP__ROUTS.main} />
                    <a href="https://интернетбезугроз.рф/" target="_blank">
                        <img
                            src={internetWithoutDangerLogo}
                            className={styels['header__social-media-icon']}
                        />
                    </a>
                    <a href="https://ncpti.su/" target="_blank">
                        <img src={ncptiLogo} className={styels['header__social-media-icon']} />
                    </a>{' '}
                    <SocialList iconClassName={styels.icon} variant="gray" />
                </div>
                <nav className={styels['header__nav']}>
                    <ul className={styels['header__nav-list']}>
                        {HEADER__NAVLINKS__DATA.map((linkData) => {
                            return (
                                <li key={linkData.name} className={styels['header__nav-list-item']}>
                                    <NavLink
                                        className={({ isActive }) =>
                                            clsx(isActive && styels['header__nav-link--active'])
                                        }
                                        to={linkData.path}
                                    >
                                        {linkData.name}
                                    </NavLink>
                                </li>
                            );
                        })}
                    </ul>
                </nav>
                {userLoadingDataStatus ? (
                    isAuth ? (
                        <div className={styels['header__profile-container']}>
                            <NavLink
                                to={APP__ROUTS.profileLayout}
                                className={styels['header__profile-redirect-link']}
                            >
                                <span className={styels['header__profile-text']}>Ваш профиль</span>
                                <CircledProfileIcon className={styels['header__profile-icon']} />
                            </NavLink>
                        </div>
                    ) : (
                        <div className={styels['header__sign-in-btn-container']}>
                            <Button
                                text="Войти"
                                type="button"
                                variant="primary"
                                onClick={toggleModalstatus}
                                className={styels.sigin_btn}
                            />
                        </div>
                    )
                ) : (
                    <ButtonSkeleton />
                )}
            </div>
        </>
    );
};

export default HeaderNavPanel;
