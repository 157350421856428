import React from 'react';
import { ISvgIcon } from '../../types/icons';

interface ISocialIcon extends ISvgIcon {
    variant?: 'white' | 'gray';
}

const Zen: React.FC<ISocialIcon> = ({ className = '', variant = 'white' }) => {
    const isWhite = variant === 'white';

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="33"
            height="32"
            viewBox="0 0 33 32"
            fill="none"
            className={className}
        >
            <rect
                x="0.359375"
                width="32"
                height="32"
                rx="16"
                fill={isWhite ? 'white' : '#525D6C'}
            />
            <path
                d="M17.9022 17.5429C16.6451 18.84 16.5537 20.4571 16.4451 24C19.7479 24 22.0279 23.9886 23.2051 22.8457C24.3479 21.6686 24.3594 19.28 24.3594 16.0857C20.8165 16.2 19.1994 16.2857 17.9022 17.5429ZM8.35938 16.0857C8.35938 19.28 8.3708 21.6686 9.51366 22.8457C10.6908 23.9886 12.9708 24 16.2737 24C16.1594 20.4571 16.0737 18.84 14.8165 17.5429C13.5194 16.2857 11.9022 16.1943 8.35938 16.0857ZM16.2737 8C12.9765 8 10.6908 8.01143 9.51366 9.15429C8.3708 10.3314 8.35938 12.72 8.35938 15.9143C11.9022 15.8 13.5194 15.7143 14.8165 14.4571C16.0737 13.16 16.1651 11.5429 16.2737 8ZM17.9022 14.4571C16.6451 13.16 16.5537 11.5429 16.4451 8C19.7479 8 22.0279 8.01143 23.2051 9.15429C24.3479 10.3314 24.3594 12.72 24.3594 15.9143C20.8165 15.8 19.1994 15.7143 17.9022 14.4571Z"
                fill={isWhite ? '#33373E' : 'white'}
            />
            <path
                d="M24.3594 16.0857V15.9143C20.8165 15.8 19.1994 15.7143 17.9022 14.4571C16.6451 13.16 16.5537 11.5429 16.4451 8H16.2737C16.1594 11.5429 16.0737 13.16 14.8165 14.4571C13.5194 15.7143 11.9022 15.8057 8.35938 15.9143V16.0857C11.9022 16.2 13.5194 16.2857 14.8165 17.5429C16.0737 18.84 16.1651 20.4571 16.2737 24H16.4451C16.5594 20.4571 16.6451 18.84 17.9022 17.5429C19.1994 16.2857 20.8165 16.1943 24.3594 16.0857Z"
                fill={isWhite ? 'white' : '#525D6C'}
            />
        </svg>
    );
};

export default Zen;
