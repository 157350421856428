import React, { ReactNode } from 'react';
import clsx from 'clsx';
import styles from './index.module.scss';

interface IRuleCard {
    className?: string;
    variant: 'orange' | 'yellow' | 'red' | 'gray';
    children: ReactNode;
}

export const RuleCard: React.FC<IRuleCard> = ({ className, variant, children }) => {
    return (
        <div
            className={clsx(
                styles.rule_card,
                className,
                {
                    orange: styles.rule_card__orange,
                    yellow: styles.rule_card__yellow,
                    red: styles.rule_card__red,
                    gray: styles.rule_card__gray,
                }[variant]
            )}
        >
            {children}
            <div
                className={clsx(
                    styles.triangle,
                    {
                        orange: styles.triangle__gray,
                        yellow: styles.triangle__gray,
                        red: styles.triangle__gray,
                        gray: styles.triangle__orange,
                    }[variant]
                )}
            ></div>
        </div>
    );
};
